<template>
  <div class="boxs">
    <van-nav-bar
        title="商家地图"
        left-text="返回"
        left-arrow
        @click-left="returnBack"
    />
    <div  style="width: 95%;margin: 0 auto">
      <h4>{{addressLatJson.name}}</h4>
      <p class="address">
        <van-icon name="location" />{{addressLatJson.address}}
      </p>
    </div>

    <van-divider />
    <div id="container"></div>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
export default {
  name: "maps",
  data() {
    return {
      addressLatJson: {}
    };
  },
  methods:{
    returnBack(){
      this.$router.back();
    }
  },
  mounted() {
    let self =this
    lazyAMapApiLoaderInstance.load().then(() => {
      // eslint-disable-next-line no-undef,no-unused-vars
      let addressLat = window.localStorage.getItem("addressLat");
      if (addressLat != null) {
        let addressLatJson = JSON.parse(addressLat);
        console.log(addressLatJson)
        self.addressLatJson =addressLatJson;
        // eslint-disable-next-line no-undef
        let map = new AMap.Map("container", {
          resizeEnable: true,
          center: [addressLatJson.lng, addressLatJson.lat],// 设置地图的中心点
          zoom: 15,
        });
        map.plugin("AMap.ToolBar",function (){
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          // eslint-disable-next-line no-undef
          map.addControl(new AMap.ToolBar({
            // 简易缩放模式，默认为 false
            liteStyle: true
          }));
        })
        // eslint-disable-next-line no-undef
         new AMap.Marker({
          // eslint-disable-next-line no-undef
          position: new AMap.LngLat(addressLatJson.lng, addressLatJson.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: addressLatJson.name,
          map: map
        });
        // marker.on('click',function(){
        //   marker.markOnAMAP({
        //     name:addressLatJson.name,
        //     position:marker.getPosition()
        //   })
        // })
      }
    });
  }
};
</script>

<style scoped lang="scss">
 .boxs {
   position: absolute;
   bottom: 0;
   right: 0;
   left: 0;
   top: 0;
   background: #FFFFFF;
   .address {
     padding-top: 5px;
     color: #cccccc;

   }
   #container {
     width: 100%;
     height: 77%;
   }

 }
</style>
